<template>
  <div :class="$style.manageBox" @click="handleOtherClick">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">{{
          $t('enterprise.deviceManagement')
        }}</span>
      </template>
      <div :class="$style.manageContent" ref="content" slot="content">
        <div @click.stop="initStatus">
          <top-data
            @onAreaClick="handleAreaClick"
            @onCountClick="handleCountClick"
          />
        </div>
        <!-- <a-tabs default-active-key="table" @change="changeTab">
          <a-tab-pane key="table" :tab="$t('hat.deviceManagement.list')"> -->
        <div :class="$style.operation">
          <div :class="$style.left">
            <div :class="$style.searchBox">
              <project-select
                v-model="searchParams.projectId"
                @change="handleProjectSelect"
                :class="$style.searchSelect"
                :defaultItems="[
                  {
                    id: '',
                    value: $t('hat.managementModal.event.project'),
                  },
                  {
                    id: 'NOT_JOIN',
                    value: $t('hat.clockRecord.notJoinProject'),
                  },
                ]"
              />
              <a-select
                show-search
                allowClear
                mode="multiple"
                :maxTagCount="1"
                :maxTagTextLength="3"
                v-model="searchParams.groupIdList"
                :placeholder="$t('hat.electronFence.selectGroup')"
                option-filter-prop="children"
                :filter-option="true"
                :class="$style.searchSelect"
                @change="() => handleSearch()"
              >
                <a-select-option
                  :value="item.pkId"
                  v-for="item in groupList"
                  :key="item.pkId"
                >
                  {{ item.deviceGroupName }}
                </a-select-option>
              </a-select>
              <a-input
                :class="$style.searchInput"
                v-model="searchParams.content"
                @input.stop="() => handleSearch()"
                :placeholder="$t('hat.deviceManagement.searchInput')"
              >
                <a-icon slot="prefix" type="search" />
                <x-icon
                  slot="suffix"
                  :class="[
                    $style.batchSearch,
                    searchParams.deviceNames ? $style.isSearch : '',
                  ]"
                  @click.stop="batchSearch"
                  type="tc-icon-batch"
                />
              </a-input>
            </div>
          </div>
          <div
            :class="[
              $style.right,
              selectedRowKeys.length > 0 ? $style.rightActive : '',
            ]"
          >
            <!-- <div
              v-if="$p.action('CREATE')"
              :class="$style.iconBtn"
              @click.stop="handleGrouping('add')"
            >
              <a-icon :class="$style.iconActive" type="appstore" />
              <span :class="$style.txtActive">{{
                $t('hat.deviceManagement.group')
              }}</span>
            </div> -->
            <div :class="$style.iconBtn" @click.stop="handleSetting">
              <a-icon :class="$style.iconActive" type="block" />
              <span :class="$style.txtActive">{{
                $t('hat.deviceManagement.batch.batchConfig')
              }}</span>
            </div>
            <div :class="$style.iconBtn" @click.stop="downloadQrcodes">
              <a-icon :class="$style.iconActive" type="download" />
              <span :class="$style.txtActive">{{
                $t('matterwebset.screenmanage.downloadtext')
              }}</span>
            </div>
            <div :class="$style.iconBtn" @click.stop="() => exportExcel()">
              <a-icon :class="$style.iconActive" type="download" />
              <span :class="$style.txtActive">{{ $t('common.export') }}</span>
            </div>
            <a-button
              type="primary"
              icon="plus"
              @click.stop="handleAdd({}, 'add')"
              style="margin-right: 15px"
              >{{ $t('hat.deviceManagement.add') }}</a-button
            >
            <a-button
              type="primary"
              v-if="$p.action('CREATE') && selectedRowKeys.length > 0"
              @click.stop="handleGrouping('add')"
              style="margin-right: 15px"
              >{{ $t('hat.deviceManagement.newGrouping') }}</a-button
            >
            <a-dropdown
              v-if="$p.action('CREATE') && selectedRowKeys.length === 0"
            >
              <a-menu slot="overlay">
                <a-menu-item @click="handleShowProjectForm('show')">
                  {{ $t('hat.clockRecord.projectManage') }}
                </a-menu-item>
              </a-menu>
              <a-button
                type="primary"
                style="margin-right: 15px"
                @click="handleShowProjectForm('add')"
                >{{ $t('hat.deviceManagement.addProject')
                }}<a-icon style="color: #fff" type="down"
              /></a-button>
            </a-dropdown>
            <!-- 通话 -->
          </div>
        </div>
        <div :class="$style.tableWrap">
          <a-table
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :columns="columns"
            :data-source="tableData"
            :pagination="pagination"
            :scroll="{ x: 2000 }"
            :row-key="record => record.deviceId"
            :loading="loading"
            @change="handlePagination"
          >
            <template slot="deviceName" slot-scope="text, record">
              <div :class="$style.deviceCell">
                <span>{{ text }}</span>
                <span
                  :style="{
                    backgroundColor: hatColors[record.color],
                    color: '#ffffff',
                    fontSize: '16px',
                  }"
                >
                  <span :class="$style.plusTxt" v-if="record.type === 'PLUS'"
                    >plus</span
                  >
                  <x-icon v-else type="tc-icon-hat-smile" />
                </span>
              </div>
            </template>
            <!-- <template slot="deviceSecretIntercept" slot-scope="text, record">
              <div :class="$style.verificationCode">
                <span>{{
                  record.showVerificationCode ? text : '**********'
                }}</span>
                <i
                  @click.stop="
                    record.showVerificationCode = !record.showVerificationCode
                  "
                  ><a-icon
                    :type="
                      record.showVerificationCode ? 'eye-invisible' : 'eye'
                    "
                /></i>
              </div>
            </template> -->
            <template slot="project" slot-scope="text">
              <a-tooltip placement="top">
                {{ text[0] }}
                <template slot="title">
                  <div v-for="item in text" :key="item">
                    {{ item }}
                  </div>
                </template>
              </a-tooltip>
            </template>
            <template slot="user" slot-scope="text, record">
              <div :class="$style.userCell">
                <a-tooltip placement="top">
                  <template slot="title">
                    <div>
                      <div v-if="record.roleCOList.length > 0">
                        {{ $t('iotScreenManage.role') }}：{{
                          roleNames(record.roleCOList)
                        }}
                      </div>
                      <div @click.stop="copyPhone(record.mobilePhone)">
                        {{
                          $t('enterpriseManagement.camera.tableColumn.phone')
                        }}：{{ record.mobilePhone }}
                      </div>
                    </div>
                  </template>
                  <template v-if="record.userId">
                    <x-oss-image
                      :class="$style.avatar"
                      v-if="record.userAvatar"
                      :ossPath="record.userAvatar"
                    />
                    <span :class="[$style.avatar, $style.noAvatar]" v-else>{{
                      record.userName.charAt(0)
                    }}</span>
                  </template>
                  <span :class="$style.userName" :title="text">{{
                    omittedText(text, 4) +
                      (record.memberStatus && memberStatus[record.memberStatus])
                  }}</span>
                </a-tooltip>
                <i v-if="record.userId" @click.stop="handleBinding(record)"
                  ><a-icon type="swap"
                /></i>
              </div>
            </template>
            <template slot="groupName" slot-scope="text, record">
              <div :class="$style.belongGroup">
                <span :title="text">{{ omittedText(text, 7) }}</span>
                <i
                  v-if="text && $p.action('UPDATE')"
                  @click.stop="handleGrouping('edit', record)"
                  ><a-icon type="edit"
                /></i>
              </div>
            </template>
            <template slot="action" slot-scope="record">
              <div :class="$style.buttonGroups">
                <a
                  v-if="$p.action('UPDATE')"
                  :class="[$style.button]"
                  @click.stop="handleEdit(record, 'edit')"
                >
                  {{ $t('hat.deviceManagement.edit') }}
                </a>
                <a
                  v-if="$p.action('UPDATE')"
                  :class="[$style.button]"
                  @click.stop="handleDelete(record)"
                >
                  {{ $t('hat.deviceManagement.remove') }}
                </a>
                <a
                  :class="[$style.button]"
                  :style="{
                    'pointer-events': manageLoading ? 'none' : 'auto',
                  }"
                  @click.stop="handleManage(record)"
                >
                  <span>{{ $t('hat.deviceManagement.manage') }}</span>
                  <span :class="$style.tipSOS" v-if="record.sosEvent">sos</span>
                  <span
                    :class="$style.tipNum"
                    v-if="!record.sosEvent && record.eventCount > 0"
                    >{{
                      record.eventCount > 99 ? '99+' : record.eventCount
                    }}</span
                  >
                </a>
                <a :class="[$style.button]" @click.stop="handleMore(record)">{{
                  $t('hat.deviceManagement.more')
                }}</a>
              </div>
            </template>
          </a-table>
        </div>
        <!-- </a-tab-pane>
          <a-tab-pane key="map" :tab="$t('hat.deviceManagement.map')">
            <DeviceMap />
          </a-tab-pane>
        </a-tabs> -->
      </div>
    </layout-content>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import TopData from './components/top-data';
import { LayoutContent } from '@triascloud/x-blocks';
import ManageModel from '@/views/hat/device/manage-model/index.vue';
import { createModal, createFormModal } from '@triascloud/x-components';
import DeviceForm from './form/device-form.vue';
import GroupingForm from './form/grouping-form.vue';
import SettingForm from './form/setting-form.vue';
import DeviceInfo from './form/device-info.vue';
import BindingForm from './form/binding-form.vue';
import ProjectForm from './form/project-form.vue';
import Tip from '@/components/tip';
import {
  getDeviceList,
  getGroupList,
  deleteDevice,
  deviceOtaUpgrade,
  executeOtaUpgrade,
  exportDeviceExcel,
} from '@/services/smart-hat/device-management';
import DownloadQrcode from './components/download-qrcode.vue';
import { Debounce } from 'lodash-decorators';
// import DeviceMap from './components/device-map';
import { statusType } from '@/views/hat/enum';
import { omittedText } from '@/views/hat/utils';
import AsyncButton from '@/components/async-button';
import { clipboardWrite } from '@triascloud/utils';
import ProjectSelect from '@/views/hat/components/project-select';
import { globalSocket } from '@triascloud/message-hub';
import { delay } from '@triascloud/utils';

/**@name 安全帽的颜色 */
export const hatColors = {
  WHITE: '#ededed',
  RED: '#F60000',
  ORANGE: '#FF8707',
  YELLOW: '#FFBF07',
  BLUE: '#4771FF',
};

@Component({
  components: {
    LayoutContent,
    ManageModel,
    TopData,
    DeviceForm,
    GroupingForm,
    SettingForm,
    DeviceInfo,
    BindingForm,
    DownloadQrcode,
    // DeviceMap,
    AsyncButton,
    ProjectSelect,
  },
})
export default class HatManage extends Vue {
  copyPhone(val) {
    clipboardWrite(val).then(() => {
      this.$message.success('复制成功');
    });
  }

  roleNames(list) {
    let str = list.reduce((v, a) => v + a.roleName + '、', '');
    if (str.slice(-1) === '、') {
      str = str.slice(0, -1);
    }
    return str;
  }

  handleOtherClick() {
    if (this.subType) {
      this.handleCountClick('');
    }
  }

  async created() {
    globalSocket.close();
    // 延迟连接，不然清除不了上一个连接
    await delay(0);
    globalSocket.connect(); // socket重连
  }

  async mounted() {
    await this.getTableList();
    await this.getGroupList();
  }
  omittedText = omittedText;
  statusList = statusType;
  memberStatus = {
    BLOCK: '(已停用)',
    OK: '',
    RESIGNED: '(已离职)',
  };
  hatColors = hatColors;
  tableData = [];
  searchParams = {
    content: '',
    projectId: undefined,
    status: undefined,
    deviceNames: '',
    groupIdList: [],
  };
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('hat.clockRecord.index'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('hat.deviceManagement.equipmentColor'),
        dataIndex: 'deviceName',
        ellipsis: true,
        scopedSlots: { customRender: 'deviceName' },
      },
      // {
      //   align: 'left',
      //   title: this.$t('hat.deviceManagement.form.verificationCode'),
      //   dataIndex: 'deviceSecretIntercept',
      //   ellipsis: true,
      //   scopedSlots: { customRender: 'deviceSecretIntercept' },
      // },
      {
        title: this.$t(
          'enterpriseManagement.camera.tableColumn.equipmentStatus',
        ),
        dataIndex: 'status',
        width: 100,
        ellipsis: true,
        customRender: text => this.statusList[text],
      },
      {
        title: this.$t('hat.deviceManagement.currentProject'),
        dataIndex: 'projectNames',
        width: 200,
        ellipsis: true,
        scopedSlots: { customRender: 'project' },
      },
      {
        title: this.$t('hat.deviceManagement.grouping'),
        dataIndex: 'groupName',
        ellipsis: true,
        scopedSlots: { customRender: 'groupName' },
      },
      {
        title: this.$t('hat.deviceManagement.currentWearer'),
        dataIndex: 'userName',
        ellipsis: true,
        scopedSlots: { customRender: 'user' },
      },
      {
        align: 'left',
        title: this.$t('hat.deviceManagement.lastOnlineTime'),
        width: 180,
        dataIndex: 'lastOnlineTime',
        ellipsis: true,
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        title: this.$t('hat.deviceManagement.form.remark'),
        width: 160,
        dataIndex: 'mark',
        ellipsis: true,
      },
      {
        title: this.$t('enterpriseManagement.camera.tableColumn.operation'),
        fixed: 'right',
        key: 'operation',
        ellipsis: true,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: parseInt(localStorage.getItem('hat_device_pageSize')) || 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  handlePagination(data) {
    this.pagination = data;
    localStorage.setItem('hat_device_pageSize', this.pagination.pageSize);
    this.getTableList();
  }
  loading = false;
  async getTableList() {
    this.selectedRowKeys = [];
    this.selectedRows = [];
    this.loading = true;
    const data = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
      ...this.searchParams,
    };
    data.groupIdList = data.groupIdList.join(',');
    if (this.subType) {
      data['subType'] = this.subType;
    }
    try {
      const { records, total } = await getDeviceList(data);
      this.tableData = records.map(item => {
        item.showVerificationCode = false;
        return item;
      });
      this.pagination.total = total;

      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  @Debounce(500)
  handleSearch() {
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getTableList();
  }
  initStatus() {
    if (this.searchParams.status) {
      this.searchParams.status = undefined;
      this.getTableList();
    }
    if (this.subType) {
      this.handleCountClick('');
    }
  }
  handleAreaClick(status) {
    if (status) {
      // 再次点击相同区域清空条件
      this.searchParams.status =
        this.searchParams.status === status ? undefined : status;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTableList();
    }
  }
  subType = '';
  handleCountClick(type) {
    this.searchParams = {
      content: '',
      projectId: undefined,
      status: undefined,
      deviceNames: '',
      groupIdList: [],
    };
    this.subType = type;
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getTableList();
  }
  selectedRowKeys = [];
  selectedRows = [];
  onSelectChange(selectedRowKeys, selectedRows) {
    this.selectedRowKeys = selectedRowKeys;
    this.selectedRows = selectedRows;
  }
  changeTab() {}
  handleAdd(row, operationType) {
    this.showFormModel(row, operationType);
  }
  handleEdit(row, operationType) {
    this.showFormModel(row, operationType);
  }
  async showFormModel(row, operationType) {
    try {
      const result = await createFormModal(
        () => <DeviceForm editData={row} operationType={operationType} />,
        {
          width: '450px',
          maskClosable: false,
          title:
            operationType === 'add'
              ? this.$t('hat.deviceManagement.add')
              : this.$t('hat.deviceManagement.editDevice'),
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
  manageLoading = false;
  async handleManage(record) {
    this.manageLoading = true;
    try {
      let OTAData = await deviceOtaUpgrade(record.deviceId);
      if (record.status === 'ONLINE' && OTAData) {
        try {
          await createFormModal(
            () => (
              <p style="text-align: center;width: 80%;margin: 0 auto;">
                {OTAData.mark}
              </p>
            ),
            {
              width: '442px',
              title: `${OTAData.destVersion}升级通知`,
              footer: (h, submit, cancel) => [
                <AButton
                  ghost
                  onClick={async () => {
                    await executeOtaUpgrade({
                      clientConfirmStatus: 'NEGLECT',
                      otaUpgradeId: OTAData.otaUpgradeId,
                    });
                    cancel();
                  }}
                >
                  忽略
                </AButton>,
                <AButton
                  type="primary"
                  ghost
                  onClick={async () => {
                    this.openManageModal(record);
                    cancel();
                    await executeOtaUpgrade({
                      clientConfirmStatus: 'CONFIRM',
                      otaUpgradeId: OTAData.otaUpgradeId,
                    });
                    this.$message.warning(
                      this.$t('hat.deviceManagement.uploadModal.message'),
                    );
                  }}
                >
                  升级
                </AButton>,
              ],
            },
          );
        } catch {
          return false;
        }
      } else {
        this.openManageModal(record);
      }
    } catch {
      this.openManageModal(record);
    } finally {
      this.manageLoading = false;
    }
  }
  openManageModal(record) {
    if (!record.userId) {
      this.$message.warning('请及时添加佩戴人员，保证设备数据的溯源性！');
    }
    createModal(() => <ManageModel record={record} />, {
      width: 1280,
      title: this.$t('hat.deviceManagement.manage'),
      maskClosable: false,
      className: this.$style.ManageModel,
      // onClose: () => this.getTableList(),
    });
  }
  changeOperationType(flag) {
    flag
      ? this.setTitle(this.$t('hat.deviceManagement.newGrouping'))
      : this.setTitle(this.$t('hat.deviceManagement.joinGroup'));
  }
  setTitle(title) {
    document.getElementById('group_modal_title').innerText = title;
  }
  async handleGrouping(type, record) {
    if (type === 'add') {
      if (this.selectedRows.length === 0) {
        this.$message.warning(this.$t('hat.deviceManagement.selectOne'));
        return;
      }
      const flag = this.selectedRows.some(item => {
        return item.groupId === '';
      });
      if (!flag) {
        this.$message.warning('每台设备仅允许加入一个编组！');
        return;
      }
    }
    try {
      const result = await createFormModal(
        () => (
          <GroupingForm
            ref="group"
            data={record}
            type={type}
            selectData={this.selectedRows}
            onChangeOperationType={v => this.changeOperationType(v)}
          />
        ),
        {
          width: '450px',
          maskClosable: false,
          title: (
            <span id="group_modal_title">
              {type === 'add'
                ? this.$t('hat.deviceManagement.newGrouping')
                : this.$t('hat.deviceManagement.editGrouping')}
            </span>
          ),
          footer: (h, submit, cancel) => (
            <div>
              <AButton ghost onClick={() => cancel()}>
                {this.$t('msg.cancel')}
              </AButton>
              {this.$p.action('DELETE') ? (
                <AButton
                  type="primary"
                  style={`display: ${type === 'add' ? 'none' : 'inline-block'}`}
                  ghost
                  onClick={() => this.deleteGroup(cancel)}
                >
                  {this.$t('hat.deviceManagement.removeGroup')}
                </AButton>
              ) : (
                ''
              )}
              <AButton type="primary" onClick={() => submit()}>
                {this.$t('msg.confirm')}
              </AButton>
            </div>
          ),
        },
      );
      if (result) {
        this.selectedRows = [];
        this.selectedRowKeys = [];
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
  async deleteGroup(cancel) {
    const result = await this.$refs.group.deleteGroup();
    if (result) {
      cancel();
      this.getTableList();
    }
  }
  async handleBinding(record) {
    const flag = record.bindType === 'MEMBER';
    try {
      const result = await createFormModal(
        () => <BindingForm bindingUser={flag} data={record} />,
        {
          width: 470,
          title: this.$t('hat.deviceManagement.operateConfirm'),
          maskClosable: false,
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
  async handleSetting() {
    if (this.selectedRows.length === 0) {
      this.$message.warning(this.$t('hat.deviceManagement.selectOne'));
      return;
    }
    try {
      const result = await createFormModal(
        () => <SettingForm deviceIds={this.selectedRowKeys} />,
        {
          width: 500,
          title: this.$t('hat.deviceManagement.batch.batchConfig'),
          maskClosable: false,
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
  batchValue = '';
  async batchSearch() {
    try {
      await createFormModal(
        () => (
          <div>
            <div style="color: var(--font-info); margin-bottom: 10px;">
              每行仅限一个设备号
            </div>
            <ATextarea v-model={this.batchValue} autosize={false} rows={10} />
          </div>
        ),
        {
          width: 520,
          title: '批量筛选',
          maskClosable: false,
          onOk: async () => {
            try {
              this.searchParams.deviceNames = this.batchValue.replace(
                /\n/g,
                ',',
              );
              await this.getTableList();
            } catch {
              return false;
            }
          },
          onCancel: () => {
            if (this.batchValue) {
              this.batchValue = '';
              this.searchParams.deviceNames = '';
              this.getTableList();
            }
          },
        },
      );
    } catch {
      return false;
    }
  }
  downloadQrcodes() {
    if (this.selectedRows.length === 0) {
      this.$message.warning(this.$t('hat.deviceManagement.selectOne'));
      return;
    }
    createModal(() => <DownloadQrcode infoList={this.selectedRows} />, {
      width: 650,
      title: this.$t('matterwebset.screenmanage.downloadtext'),
      className: this.$style.ManageModel,
    });
  }
  handleMore(record) {
    createModal(() => <DeviceInfo info={record} />, {
      width: 470,
      title: this.$t('hat.deviceManagement.deviceInfo'),
      className: this.$style.ManageModel,
    });
  }
  async handleDelete(record) {
    const text = this.$t('hat.deviceManagement.delete.title');
    const tips = this.$t('hat.deviceManagement.delete.tips');
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: this.$t('hat.deviceManagement.operateConfirm'),
          onOk: async () => {
            await deleteDevice(record.deviceId);
            this.$message.success(
              this.$t('hat.deviceManagement.delete.deleted') + '!',
            );
            this.getTableList();
          },
        },
      );
    } catch {
      return false;
    }
  }
  @Debounce(500)
  async exportExcel() {
    try {
      await exportDeviceExcel();
      this.$message.success(this.$t('common.exportSuccessful'));
    } catch {
      return false;
    }
  }
  showDeleteProject = false;
  getDeleteProjectFlag(flag) {
    this.showDeleteProject = flag;
  }
  async handleShowProjectForm(operationType) {
    this.showDeleteProject = false;
    try {
      const result = await createFormModal(
        () => (
          <ProjectForm
            ref="project"
            operationType={operationType}
            onChangeOperationType={flag => this.getDeleteProjectFlag(flag)}
          />
        ),
        {
          width: '480px',
          maskClosable: false,
          title: (
            <span id="project_modal_title">
              {operationType === 'add'
                ? this.$t('hat.deviceManagement.addProject')
                : this.$t('hat.clockRecord.projectManage')}
            </span>
          ),
          footer: (h, submit, cancel) => [
            <AButton ghost onClick={() => cancel()}>
              取消
            </AButton>,
            this.showDeleteProject ? (
              <AButton ghost onClick={() => this.$refs.project.deleteProject()}>
                删除项目
              </AButton>
            ) : (
              ''
            ),
            <AButton type="primary" ghost onClick={() => submit()}>
              确定
            </AButton>,
          ],
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
  // 编组列表
  groupList = [];
  async getGroupList() {
    try {
      const data = {
        current: 1,
        size: 200,
        projectId: this.searchParams.projectId,
      };
      this.groupList = (await getGroupList(data)).records;
      if (['', undefined, 'NOT_JOIN'].includes(this.searchParams.projectId)) {
        this.groupList.unshift({
          pkId: 'NOT_JOIN',
          deviceGroupName: this.$t('hat.clockRecord.notJoinGroup'),
        });
      }
    } catch {
      return false;
    }
  }
  async handleProjectSelect() {
    this.searchParams.groupIdList = [];
    await this.getGroupList();
    await this.handleSearch();
  }
}
</script>

<style lang="less" module>
.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;

  .title {
    font-size: 16px;
  }

  .manageContent {
    padding: 20px;
    .operation {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;

      .left,
      .right {
        display: flex;
        align-items: center;

        &.rightActive {
          .txtActive,
          .iconActive {
            color: var(--font-active);
          }
        }
      }

      .left {
        .searchBox {
          display: flex;

          .searchInput {
            margin: 0 0 0 15px;
            width: 230px;
            // input {
            //   width: 110px;
            //   transition: width 200ms linear;
            //   &:focus {
            //     width: 200px;
            //   }
            // }
            .batchSearch {
              font-size: 18px;
              cursor: pointer;
              transition: all 0.3s;
            }
            .batchSearch:hover {
              color: var(--primary);
            }
            .isSearch {
              color: var(--primary);
            }
          }

          .searchSelect {
            width: 180px;
          }
        }
      }

      .right {
        .iconBtn {
          margin: 0 20px 0 10px;
          color: var(--font);
          font-size: 14px;
          cursor: pointer;
          svg {
            transform: translateY(0.5px);
            margin-right: 10px;
          }
        }

        .iconBtn:hover {
          color: var(--primary);
        }
      }
    }

    .tableWrap {
      .belongGroup {
        color: var(--primary);

        & > span {
          margin-right: 8px;
        }

        & > i {
          cursor: pointer;
        }
      }

      .verificationCode {
        & > span {
          margin-right: 12px;
        }

        & > i {
          font-size: 18px;
          cursor: pointer;
        }
      }

      .deviceCell {
        display: flex;
        align-items: center;

        span:last-of-type {
          width: 32px;
          height: 16px;
          margin-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
        }
      }

      .userCell {
        color: var(--primary);

        .avatar {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          object-fit: cover;
          transform: translateY(28%);
        }
        .noAvatar {
          display: inline-block;
          color: #fff;
          background-color: var(--x-modal-select-avatar);
          text-align: center;
          line-height: 24px;
          font-size: 12px;
          transform: translateY(0);
        }

        .userName {
          margin: 0 5px;
        }

        & > i {
          cursor: pointer;
        }
      }
    }
  }

  :global {
    .ant-table-wrapper .ant-spin-container .ant-table-body,
    .ant-table-wrapper .ant-table-scroll .ant-table-body {
      flex: 1;
      overflow: auto !important;
      max-height: none !important;
    }
    // .ant-spin-container {
    //   min-height: 58vh;
    // }
    .ant-table-fixed-left,
    .ant-table-fixed-right {
      height: auto;
    }
  }

  .buttonGroups {
    display: flex;
    gap: 20px;

    .button {
      position: relative;
      .tipNum,
      .tipSOS {
        height: 20px;
        min-width: 20px;
        padding: 0 2px;
        text-align: center;
        line-height: 20px;
        position: absolute;
        font-size: 12px;
        border-radius: 20px;
        background-color: var(--primary);
        color: #fff;
        right: -10px;
        top: 0px;
      }
      .tipSOS {
        line-height: 16px;
        background-color: #f60000;
      }
    }
  }
}

.ManageModel {
  :global {
    .ant-model-body {
      padding: 0;
      margin-bottom: 0;
    }
  }
}
.plusTxt {
  font-size: 20px;
  font-weight: bolder;
  transform: scale(0.5);
  margin: 0 !important;
  color: #ffffff;
}
</style>
