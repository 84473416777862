<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item
      :label="$t('hat.deviceManagement.bind.menber')"
      prop="user"
      v-if="bindingUser"
    >
      <a-select
        show-search
        allowClear
        v-model="form.user"
        :placeholder="$t('iotScreenManage.pleaseSelect')"
        option-filter-prop="children"
        :filter-option="true"
      >
        <a-select-option
          :value="item.id"
          v-for="item in userList"
          :key="item.id"
        >
          {{ item.name + (item.memberStatus === 'RESIGNED' ? `(已离职)` : '') }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('hat.deviceManagement.bind.project')"
      prop="project"
      v-else
    >
      <a-row :gutter="6" :class="$style.row">
        <a-col :span="15"
          ><a-select
            show-search
            allowClear
            v-model="form.project"
            :placeholder="$t('iotScreenManage.pleaseSelect')"
            option-filter-prop="children"
            :filter-option="true"
            @change="onChange"
          >
            <a-select-option
              :value="item.id"
              v-for="item in projectList"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="9">
          <a-select
            show-search
            allowClear
            v-model="form.user"
            :placeholder="$t('iotScreenManage.pleaseSelect')"
            option-filter-prop="children"
            :filter-option="true"
          >
            <a-select-option
              :value="item.id"
              v-for="item in userList"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select></a-col
        >
      </a-row>
    </a-form-model-item>
    <p :class="$style.tipText">
      {{ $t('hat.deviceManagement.editModal.') }}
    </p>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  putBinding,
  getUserList,
} from '@/services/smart-hat/device-management';

@Component()
export default class BindingForm extends Vue {
  @Prop({ type: Boolean, default: true }) bindingUser;
  @Prop({ type: Object, default: () => {} }) data;
  mounted() {
    this.initData();
  }
  form = {
    user: undefined,
    project: undefined,
  };
  rules = {
    // user: [
    //   {
    //     required: true,
    //     message: '佩戴成员不能为空',
    //   },
    // ],
    // project: [
    //   {
    //     required: true,
    //     message: '项目不能为空',
    //   },
    // ],
  };
  userList = [];
  projectList = [];
  async initData() {
    // 项目绑定需求删除
    // this.form.project = this.data.projectId;
    // this.projectList = this.data.projects.map(item => ({
    //   id: item.projectId,
    //   name: item.projectName,
    // }));
    if (this.data.bindType === 'PROJECT') {
      this.userList = (await getUserList(this.data.projectId)).map(item => ({
        id: item.idxMemberId,
        name: item.memberName || '-',
      }));
    } else {
      this.userList = this.data.users.map(item => ({
        id: item.userId,
        name: item.userName,
        memberStatus: item.memberStatus,
      }));
    }
    this.form.user = this.data.userId;
  }
  async onChange(v) {
    this.form.user = undefined;
    try {
      this.userList = (await getUserList(v)).map(item => ({
        id: item.idxMemberId,
        name: item.memberName || '-',
      }));
    } catch (error) {
      this.userList = [];
      return false;
    }
  }
  async getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const data = {
              deviceId: this.data.deviceId,
              userId: this.form.user,
              projectId: this.form.project,
            };
            await putBinding(data);
            this.$message.success(
              this.$t('hat.deviceManagement.editModal.modified'),
            );
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module>
.tipText {
  color: #999999;
  margin-top: 10px;
  font-size: 13px;
}
</style>
