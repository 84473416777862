<template>
  <div>
    <div :class="$style.deviceInfo">
      <div :class="$style.qrcode">
        <qr-code
          :option="qrCodeOption"
          :width="200"
          :height="200"
          :qrSize="180"
          :qrTextSize="14"
          :qrContent="
            `AISCAP,${info.deviceName},${$moment(info.factoryTime).format(
              'YYYY-MM-DD',
            )},${hatColors[info.color]}`
          "
          :qrText="`设备码: ${info.deviceName}`"
          id="qrcode"
        />
      </div>
      <div :class="$style.link">
        <p>设备信息</p>
        <a :href="info.detailUrl" target="_blank">{{ info.detailUrl }}</a>
      </div>
    </div>
    <div :class="$style.buttonGroups" id="test">
      <a-button type="text" @click="copy">复制设备信息</a-button>
      <a-button type="primary" :class="$style.download" @click="handleDownload"
        >下载二维码</a-button
      >
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { clipboardWrite } from '@triascloud/utils';
import QrCode from '@/views/hat/device/components/qrcode.vue';
import { getDeviceDetail } from '@/services/smart-hat/device';
import { crossStorageModule } from '@/enum/store';

const hatColors = {
  WHITE: '白',
  RED: '红',
  ORANGE: '橙',
  YELLOW: '黄',
  BLUE: '蓝',
};
@Component({ components: { QrCode } })
export default class DeviceInfo extends Vue {
  @Prop({ type: Object }) info;
  @crossStorageModule.State('tenant') tenant;

  hatColors = hatColors;
  qrCodeOption = {
    color: {
      background: '#00000000',
      foreground: '#000000FF',
    },
    margin: 3, // 生成的二维码的外边距
  };
  mounted() {
    this.getDeviceDetail();
  }
  copy() {
    clipboardWrite(
      `设备码：${this.info.deviceName}\n验证码：${
        this.info.deviceSecretIntercept
      }\n颜色：${hatColors[this.info.color]}\n所属企业：${
        this.tenant.enterpriseShortName
      }\n当前所属项目：${this.info.projectName}\n当前佩戴人员：${
        this.info.userName
      }\n产品分类：${
        this.info.type === 'BASE' ? '基础版' : '升级版'
      }\n固件版本：${this.detail.firmwareVersion}`,
    ).then(() => {
      this.$message.success('复制成功');
    });
  }
  getImgSrc(dom) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 500;
    canvas.height = 500;
    ctx.drawImage(dom, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL('image/png');
  }
  async handleDownload() {
    const qrcodePic = document.getElementById('qrcode');
    const dom = document.createElement('a');
    dom.href = this.getImgSrc(qrcodePic);
    dom.download = this.info.deviceName;
    document.body.appendChild(dom);
    dom.click();
  }
  detail = {};
  async getDeviceDetail() {
    this.detail = await getDeviceDetail(this.info.deviceId);
  }
}
</script>

<style lang="less" module>
.deviceInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  .qrcode {
    min-width: 160px;
    text-align: center;
    & > p {
      // white-space: nowrap;
      color: var(--font);
    }
  }
  .link {
    margin: 0 0 20px;
    text-align: center;
    & > p {
      margin: 0;
      color: #999999;
    }
  }
}
.buttonGroups {
  text-align: right;
  .download {
    margin-left: 20px;
  }
}
</style>
