<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item prop="pmName">
      <span slot="label" :class="$style.group">
        {{ $t('enterpriseManagement.camera.bind.label1') }}
        <div :class="$style.groupOperation">
          <a @click="changeFormType('add')" v-if="!canEdit">{{
            $t('hat.deviceManagement.form.new')
          }}</a>
          <a @click="changeFormType('edit')" v-if="!canEdit">{{
            $t('electricBox.btn.edit')
          }}</a>
          <a-tooltip :title="$t('hat.managementModal.project.tip1')">
            <a v-if="canEdit" @click="onAssociated">{{
              $t('hat.managementModal.project.relate')
            }}</a>
          </a-tooltip>
        </div>
      </span>
      <a-input
        v-if="canEdit"
        :placeholder="$t('common.enter')"
        v-model="form.pmName"
        :disabled="!canEdit"
        :maxLength="32"
      />
      <a-select
        v-if="!canEdit"
        show-search
        allowClear
        v-model="form.pkId"
        :placeholder="$t('hat.deviceManagement.form.select')"
        option-filter-prop="children"
        :filter-option="true"
        @change="handleProjectSelectChange"
        @search="v => projectSelectSearch(v)"
      >
        <a-select-option
          :value="item.pkId"
          v-for="item in projectList"
          :key="item.pkId"
        >
          {{ item.pmName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item prop="ownerList">
      <span slot="label">
        {{ $t('hat.managementModal.project.principalOrDepartment') }}&nbsp;
        <a-tooltip :title="$t('hat.managementModal.project.tip2')">
          <a-icon type="info-circle" />
        </a-tooltip>
      </span>
      <x-member-select
        v-model="form.ownerList"
        :view="!canEdit"
        :value="range"
        @input="updateRangeValue"
        contextUser="CURRENT_DEPT"
        :type="[
          'org-user',
          'user',
          {
            tab: $t('hat.managementModal.project.department'),
            Component: 'org',
          },
        ]"
        :limit="2"
        :multiple="true"
      />
    </a-form-model-item>
    <a-form-model-item
      :label="
        $t('hat.deviceManagement.manage') + $t('hat.deviceManagement.group')
      "
    >
      <div :class="$style.groupManage">
        <div :class="$style.noGroup" v-if="!hasGroup">
          <empty-content :class="$style.noData" label="" />
          <div>
            {{ $t('hat.managementModal.project.noGroup')
            }}<a @click="openGroupModal('add', {})">{{
              $t('hat.managementModal.project.create')
            }}</a>
          </div>
          <div :class="$style.tip">
            <span>{{ $t('common.tips.prompt') }}：</span
            >{{ $t('hat.managementModal.project.tip3') }}
          </div>
        </div>
        <div :class="$style.group" v-if="hasGroup">
          <div :class="$style.left">
            <div :class="$style.top">
              <a-input
                v-model="groupInputName"
                :placeholder="$t('hat.deviceManagement.form.inputGroupName')"
                @input.stop="() => handleGroupSearch()"
                :class="$style.groupInput"
                allowClear
              >
                <a-icon slot="prefix" type="search" />
              </a-input>
            </div>
            <div :class="$style.list">
              <empty-content
                style="height: 90%"
                label=""
                v-if="!groupList.length"
              />
              <div
                :class="$style.item"
                v-for="item in groupList"
                :key="item.id"
              >
                <a-checkbox
                  v-model="item.checked"
                  :disabled="!canEdit"
                  @change="groupCheckChange(item)"
                />
                <span
                  :class="$style.name"
                  @click="getGroupDeviceList(item.id)"
                  >{{ item.name }}</span
                >
              </div>
            </div>
            <div :class="$style.bottom">
              <a-icon
                type="sync"
                :class="$style.icon"
                :title="$t('hat.managementModal.project.refreshGroup')"
                @click="getGroupList"
              />
              <a-icon
                type="plus-square"
                :class="$style.icon"
                :title="$t('hat.deviceManagement.newGrouping')"
                @click="openGroupModal('add', {})"
              />
            </div>
          </div>
          <div :class="$style.right">
            <div :class="$style.top">
              <span>
                {{
                  groupDeviceList.length
                    ? `${groupName}(${groupDeviceList.length}台)`
                    : groupName
                }}
              </span>
              <a-icon
                :class="$style.icon"
                v-if="groupName"
                type="form"
                @click="openGroupModal('edit', { groupName, groupId })"
              />
            </div>
            <div :class="$style.list">
              <empty-content
                style="height: 90%"
                label=""
                v-if="!groupDeviceList.length"
              />
              <div
                :class="$style.item"
                v-for="item in groupDeviceList"
                :key="item.deviceId"
                :title="
                  `${item.deviceName}${
                    item.userName ? `(${item.userName})` : ''
                  }`
                "
              >
                <x-icon type="tc-icon-hat-smile" :class="$style.icon" />
                <span>{{ item.deviceName }}</span>
                <span v-if="item.userName">({{ item.userName }})</span>
              </div>
            </div>
            <div :class="$style.bottom">
              <span>负责人:</span>
              <div :class="$style.tagList">
                <div
                  :class="$style.userTag"
                  v-for="item in groupUserList"
                  :key="item.principalId"
                >
                  <XOssImage
                    v-if="item.principalPicture"
                    :class="$style.avatar"
                    :ossPath="item.principalPicture"
                  />
                  <span :class="[$style.avatar, $style.noAvatar]" v-else>
                    {{ item.principalName.charAt(0) }}
                  </span>
                  <span :class="$style.name">{{ item.principalName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div :class="$style.tipText" v-if="canEdit">
          包含{{groupNum}}个编组，共{{deviceNum}}台设备
        </div> -->
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  getGroupList,
  getGroupById,
  getProjectList,
  addProject,
  deleteProject,
} from '@/services/smart-hat/device-management';
import { createFormModal } from '@triascloud/x-components';
import GroupingForm from './grouping-form.vue';
import EmptyContent from '@/components/empty-content';
import { Debounce } from 'lodash-decorators';
import { crossStorageModule } from '@/enum/store.js';
import Tip from '@/components/tip';

@Component({ components: { EmptyContent } })
export default class ProjectForm extends Vue {
  @Prop({ type: String, default: '' }) operationType;
  @crossStorageModule.State user;

  form = {
    pmName: '',
    pkId: undefined,
    groupIdList: [],
    ownerList: [],
  };
  rules = {
    pmName: [
      {
        required: true,
        message: this.$t('hat.managementModal.project.projectNameRule'),
      },
    ],
  };
  get canEdit() {
    return this.formType !== 'show';
  }
  formType = 'add';
  async mounted() {
    this.formType = this.operationType;
    await this.initData();
    await this.getGroupList();
  }
  async initData() {
    if (this.formType === 'add') {
      this.form.ownerList = [
        {
          text: this.user.memberName,
          value: this.user.pkId,
          avatar: this.user.avatar,
          memberStatus: this.user.accountStatus,
          type: 'user',
        },
      ];
    } else if (this.formType === 'show') {
      await this.getProjectList();
      if (this.projectList.length) {
        this.form = { ...this.projectList[0] };
        this.form.ownerList = this.projectList[0].ownerList.map(item => ({
          text: item.name,
          value: item.id,
          avatar: item.avatar,
          memberStatus: 'OK',
          type: item.type.toLowerCase(),
        }));
      } else {
        this.form.pmName = ' ';
      }
    }
  }
  changeFormType(type) {
    this.$emit('changeOperationType', type === 'edit');
    this.formType = type;
    this.getGroupList();
    const titleDom = document.getElementById('project_modal_title');
    if (type === 'add') {
      this.form = {
        pmName: '',
        pkId: undefined,
        groupIdList: [],
        // geofenceId: undefined,
        ownerList: [
          {
            text: this.user.memberName,
            value: this.user.pkId,
            avatar: this.user.avatar,
            memberStatus: this.user.accountStatus,
            type: 'user',
          },
        ],
        // isDefault: false,
      };
      this.groupList.forEach(item => {
        item.checked = false;
      });
      titleDom.innerText = this.$t('hat.deviceManagement.addProject');
    } else if (type === 'edit') {
      titleDom.innerText = this.$t('hat.managementModal.project.editProject');
    }
  }
  // 项目列表
  projectList = [];
  async getProjectList(input = undefined) {
    const params = {
      current: 1,
      size: 200,
      input,
    };
    const { records } = await getProjectList(params);
    this.projectList = records;
  }
  @Debounce(300)
  projectSelectSearch(keyword) {
    this.getProjectList(keyword);
  }
  handleProjectSelectChange(v) {
    const projectObj = this.projectList.find(item => item.pkId === v);
    this.form = { ...projectObj };
    this.form.ownerList = projectObj.ownerList.map(item => ({
      text: item.name,
      value: item.id,
      avatar: item.avatar,
      memberStatus: 'OK',
      type: item.type.toLowerCase(),
    }));
    this.getGroupList();
  }
  async deleteProject() {
    const text = `确认删除项目"${this.form.pmName}"`;
    const tips = '删除后项目负责人将无法管理对应设备！';
    return new Promise((resolve, reject) => {
      try {
        createFormModal(
          () => (
            <Tip iconStyle={{ padding: '0 0 22px' }}>
              <template slot="txt">
                <span>{text}</span>
              </template>
              <span slot="subTxt">{tips}</span>
            </Tip>
          ),
          {
            width: '442px',
            title: '提示',
            onOk: async () => {
              try {
                this.formType = this.operationType;
                this.$emit('changeOperationType', false);
                await deleteProject(this.form.pkId);
                this.$message.success('删除成功!');
                await this.initData();
                await this.getGroupList();
                resolve(true);
              } catch {
                reject(false);
              }
            },
          },
        );
      } catch {
        resolve(false);
      }
    });
  }
  // 选择成员
  range = [];
  updateRangeValue(data) {
    this.range = data[0];
  }
  // 编组列表
  groupList = [];
  groupInputName = '';
  hasGroup = true;
  async getGroupList() {
    try {
      const data = {
        current: 1,
        size: 200,
        input: this.groupInputName,
      };
      this.groupList = (await getGroupList(data)).records.map(item => ({
        id: item.pkId,
        name: item.deviceGroupName,
        checked: this.form.groupIdList.includes(item.pkId),
      }));
      this.hasGroup = this.groupList.length;
      // 当为展示项目列表时，只展示当前项目下包含的编组
      if (this.formType === 'show') {
        this.groupList = this.groupList.filter(item => item.checked);
      }
      if (this.groupList.length) {
        await this.getGroupDeviceList(this.groupList[0].id);
      } else {
        this.groupDeviceList = [];
        this.groupUserList = [];
        this.groupName = '';
        this.groupId = '';
      }
    } catch {
      return false;
    }
  }
  @Debounce(500)
  handleGroupSearch() {
    this.getGroupList();
  }
  groupCheckChange(item) {
    if (item.checked) {
      this.form.groupIdList.push(item.id);
    } else {
      const index = this.form.groupIdList.indexOf(item.id);
      this.form.groupIdList.splice(index, 1);
    }
  }
  async openGroupModal(type, data) {
    try {
      const result = await createFormModal(
        () => (
          <GroupingForm ref="group" data={data} type={type} selectData={[]} />
        ),
        {
          width: '450px',
          maskClosable: false,
          title:
            type === 'add'
              ? this.$t('hat.deviceManagement.newGrouping')
              : this.$t('hat.deviceManagement.editGrouping'),
        },
      );
      if (result) {
        await this.getGroupList();
        await this.getGroupDeviceList(this.groupId);
      }
    } catch {
      return false;
    }
  }
  groupDeviceList = [];
  groupUserList = [];
  groupName = '';
  groupId = '';
  async getGroupDeviceList(id) {
    const {
      deviceInfo,
      principalInfo,
      deviceGroupName,
      pkId,
    } = await getGroupById(id);
    this.groupDeviceList = deviceInfo;
    this.groupUserList = principalInfo;
    this.groupName = deviceGroupName;
    this.groupId = pkId;
  }
  // 关联
  onAssociated() {
    this.$message.warning('即将开发，敬请期待！');
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (this.form.ownerList.length > 50) {
          this.$message.error('负责人/部门不能超过50个');
          valid = false;
        }
        if (valid) {
          const type = this.formType === 'add';
          try {
            const postData = { ...this.form };
            postData.groupIdList = this.groupList
              .filter(item => item.checked)
              .map(e => e.id);
            postData.ownerList = this.form.ownerList.map(item => ({
              id: item.value,
              type: item.type.toUpperCase(),
            }));
            if (this.formType === 'add') {
              this.form.pkId = undefined;
            }
            const str = type
              ? this.$t('hat.deviceManagement.editModal.addSuccess')
              : this.$t('hat.deviceManagement.editModal.modified');
            if (this.formType !== 'show') {
              await addProject(postData);
              this.$message.success(str);
            }
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module>
.tipText {
  font-size: 12px !important;
  color: #999999;
}
.group {
  .groupOperation {
    float: right;
    a {
      margin-left: 10px;
    }
  }
}
.groupManage {
  height: 280px;
  .noGroup {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border);
    font-size: 14px;
    line-height: 22px;
    .noData {
      height: 42%;
      width: 42%;
    }
    .tip {
      color: #999999;
      width: 70%;
      text-align: center;
      span {
        color: var(--font);
      }
    }
  }
  .group {
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      height: 260px;
      width: 49%;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--border);
      border-radius: 8px;
      .top,
      .bottom {
        height: 38px;
        padding: 0 8px;
        display: flex;
        align-items: center;
      }
      .top {
        justify-content: space-between;
        border-bottom: 1px solid var(--border);
      }
      .list {
        flex: 1;
        overflow: auto;
        .item {
          height: 32px;
          padding: 0 8px;
          font-size: 12px;
          border-bottom: 1px solid var(--border);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .icon {
            font-size: 16px;
            transform: translateY(1px);
            color: #d5d4d4;
            margin-right: 2px;
          }
          .name {
            cursor: pointer;
            margin-left: 5px;
          }
          .name:hover {
            color: var(--primary);
          }
        }
        // .item:last-of-type {
        //   height: 35px;
        //   border-bottom: none;
        // }
      }
      .bottom {
        border-top: 1px solid var(--border);
      }
    }
    .left {
      .top {
        padding: 0;
        .groupInput {
          :global {
            .ant-input {
              border: none;
            }
            .ant-input:focus {
              box-shadow: 0 0 0 transparent;
              border-color: red;
            }
          }
        }
      }
      .bottom {
        justify-content: center;
        .icon {
          font-size: 18px;
          margin-right: 14px;
        }
        .icon:hover {
          cursor: pointer;
          color: var(--primary);
        }
      }
    }
    .right {
      .bottom {
        padding-right: 0;
        & > span {
          font-size: 12px;
        }
        .tagList {
          height: 100%;
          padding: 2px 0;
          margin-left: 4px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          flex: 1;
          gap: 5px;
          overflow-y: auto;
          .userTag {
            display: flex;
            align-items: center;
            background: var(--tag);
            border-radius: 4px;
            border: 1px solid var(--border);
            padding: 2px 2px;
            height: 80%;
            .avatar {
              height: 20px;
              width: 20px;
              border-radius: 50%;
              object-fit: cover;
            }
            .noAvatar {
              color: #fff;
              background-color: var(--x-modal-select-avatar);
              text-align: center;
              line-height: 20px;
              font-size: 12px;
            }

            .name {
              margin: 0 5px;
              font-size: 12px;
            }
          }
        }
      }
      .top {
        & > span {
          display: inline-block;
          width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .icon:hover {
          cursor: pointer;
          color: var(--primary);
        }
      }
    }
  }
}
.fenceMap {
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
}
</style>
