<template>
  <div :id="id" :style="{ height: height, width: width }"></div>
</template>

<script>
import * as echarts from 'echarts';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { crossStorageModule } from '@/enum/store';

@Component
export default class TopPieChart extends Vue {
  @Prop({ type: String, default: '200px' }) height;
  @Prop({ type: String, default: '200px' }) width;
  @Prop({ type: Array, default: () => [] }) chartData;
  @Prop({ type: String, default: 'id' }) id;
  @Prop({ type: Array, default: () => [] }) gradientColorArray;
  @Prop({
    type: Array,
    default: () => [],
  })
  colorArray;

  @Watch('chartData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal.length) {
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  }
  @crossStorageModule.State('skin') skin;
  @Watch('skin')
  changeSkin() {
    this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get option() {
    const colors = [
      '#4FA2FF',
      '#EA7CCC',
      '#007441',
      '#FF0099',
      '#15FF00',
      '#5C6BC0',
      '#3BA272',
      '#91CC75',
      '#FAC858',
    ];
    return {
      color: [...this.colorArray, ...colors],
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '5%',
        left: 'center',
        show: false,
      },
      series: [
        {
          type: 'pie',
          radius: ['42%', '65%'],
          avoidLabelOverlap: false,
          minAngle: 10,
          label: {
            show: false,
            position: 'center',
          },
          itemStyle: {
            borderColor: this.skin.mode === 'dark' ? '#2F2F2F' : '#ffffff',
            borderWidth: 4,
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontWeight: 'normal',
            },
          },
          labelLine: {
            show: false,
          },
          data: this.initChartData(this.chartData),
        },
      ],
    };
  }
  initChartData(chartData) {
    // 判断是否是渐变色
    if (this.gradientColorArray.length) {
      chartData.map((item, index) => {
        if (this.gradientColorArray[index]) {
          item.itemStyle = {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: this.gradientColorArray[index][0] },
                { offset: 1, color: this.gradientColorArray[index][1] },
              ],
            },
          };
        }

        return item;
      });
    }
    return chartData;
  }
  chart = null;
  initChart() {
    if (this.chartData.length) {
      this.chart = echarts.init(document.getElementById(this.id));
      const options = this.option;
      // 添加区域点击事件
      this.chart.on('click', param => {
        param.event.event.stopPropagation();
        this.$emit('onAreaClick', param);
      });
      this.chart.setOption(options);
    }
  }
}
</script>

<style scoped></style>
