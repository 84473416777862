<template>
  <div :class="$style.topData">
    <div :class="$style.item" v-for="(item, idx) in topDataOptions" :key="idx">
      <div :class="$style.info">
        <x-icon :class="$style.iconStyle" :type="item.icon" />
        <span :class="$style.number" :style="{ color: item.color }">{{
          countObj[item.number]
        }}</span>
        <span :class="$style.text">{{ item.text }}</span>
      </div>
      <div :class="$style.chart">
        <PieChart
          :id="item.number"
          :chartData="item.chartData"
          @onAreaClick="params => handleAreaClick(params, item.number)"
          :gradientColorArray="item.gradientColors"
          :colorArray="item.colors"
          v-if="item.chartData.length"
        />
        <empty-content
          :style="{ height: '100%', width: '220px' }"
          label=""
          v-else
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import PieChart from './pie-chart.vue';
import { getDeviceStatistics } from '@/services/smart-hat/device-management';
import EmptyContent from '@/components/empty-content';
import { crossStorageModule } from '@/enum/store.js';

/** @name 饼图的类型 */
const PIET_KEY = {
  /** @name 设备总数 */
  totalNumber: 'totalNumber',
  /** @name 今日警报 */
  alarmNumber: 'alarmNumber',
  /** @name 今天提醒 */
  tipNumber: 'tipNumber',
  /** @name 今日通知 */
  noticeNumber: 'noticeNumber',
};

@Component({
  components: {
    PieChart,
    EmptyContent,
  },
})
export default class TopData extends Vue {
  @crossStorageModule.State locale;

  mounted() {
    this.getChartData();
  }
  topDataOptions = [
    {
      number: PIET_KEY.totalNumber,
      text: this.$t('electricBox.list.deviceCount'),
      icon: 'tc-icon-equipment',
      color: '#947dfa',
      chartData: [],
      colors: ['#004EC4', '#00BDC4', '#4671FE'],
      gradientColors: [],
    },
    {
      number: PIET_KEY.alarmNumber,
      text: this.$t('hat.deviceManagement.alarm'),
      icon: 'tc-icon-alarm',
      color: '#DA2031',
      chartData: [],
      colors: ['#DA2031', '#ffbf00', '#f9db83'],
      gradientColors: [
        ['#DA2031', '#DA2031'],
        ['#DA2031', '#ffbf00'],
        ['#DA2031', '#f9db83'],
        ['#DA2031', '#faeaba'],
        ['#DA2031', '#f7edcc'],
        ['#DA2031', '#ffffff'],
      ],
    },
    {
      number: PIET_KEY.tipNumber,
      text: this.$t('hat.deviceManagement.remind'),
      icon: 'tc-icon-reminder',
      color: '#FFDD00',
      chartData: [],
      colors: ['#FFDD00', '#ff6200', '#faa46f'],
      gradientColors: [
        ['#FFDD00', '#FFDD00'],
        ['#FFDD00', '#f98e4b'],
        ['#FFDD00', '#faa46f'],
        ['#FFDD00', '#fbb081'],
        ['#FFDD00', '#ffe3d2'],
        ['#FFDD00', '#ffffff'],
      ],
    },
    {
      number: PIET_KEY.noticeNumber,
      text: this.$t('hat.deviceManagement.notice'),
      icon: 'tc-icon-notice',
      color: '#006FB9',
      chartData: [],
      colors: ['#006FB9', '#019aff', '#57bbfe'],
      gradientColors: [
        ['#006FB9', '#006FB9'],
        ['#006FB9', '#019aff'],
        ['#006FB9', '#57bbfe'],
        ['#006FB9', '#4cb7fe'],
        ['#006FB9', '#a1d9fe'],
        ['#006FB9', '#d2ebfb'],
      ],
    },
  ];
  countObj = {
    totalNumber: 0,
    alarmNumber: 0,
    tipNumber: 0,
    noticeNumber: 0,
  };
  async getChartData() {
    try {
      const { devices, alerts, notice, reminds } = await getDeviceStatistics();
      const arr = [devices, alerts, reminds, notice];
      Object.keys(this.countObj).forEach((item, index) => {
        const { totalNumber, data } = this.getStatistic(arr[index]);
        this.countObj[item] = totalNumber;
        this.topDataOptions[index].chartData = data;
      });
    } catch {
      return false;
    }
  }
  getStatistic(chartData) {
    const data = chartData.map(item => ({
      value: item.number,
      name: this.locale === 'en_US' ? item.code : item.name,
      code: item.code,
    }));
    let totalNumber = 0;
    if (chartData.length) {
      chartData.forEach(item => {
        totalNumber += item.number;
      });
    }
    return {
      data,
      totalNumber,
    };
  }
  oldCountType = '';
  handleAreaClick(params, type) {
    if (type === PIET_KEY.totalNumber) {
      const statusArr = [
        'DISABLE',
        'EXPIRED',
        'OFFLINE',
        'ONLINE',
        'STANDBY',
        'UNACTIVE',
      ];
      const status = statusArr.includes(params.data.code)
        ? params.data.code
        : undefined;
      this.$emit('onAreaClick', status);
    } else {
      if (this.oldCountType !== params.data.code) {
        this.oldCountType = params.data.code;
      } else {
        this.oldCountType = '';
      }
      this.$emit('onCountClick', this.oldCountType);
    }
  }
}
</script>
<style lang="less" module>
.topData {
  display: flex;
  justify-content: space-between;
  .item {
    width: 24%;
    height: 150px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--block-bg);
    box-shadow: 0px 0px 5px 0px #e1e1e1;
    border-radius: 8px;
    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .iconStyle {
        font-size: 36px;
      }
      .number {
        text-indent: 6px;
        font-size: 32px;
        margin-bottom: 10px;
      }
      .text {
        text-indent: 6px;
        font-size: 14px;
        color: #a8a7a7;
      }
    }
  }
  .chart {
    display: flex;
    align-items: center;
  }
}
</style>
