<template>
  <div :class="$style.qrContainer">
    <a-spin :spinning="loading" />
    <div :class="$style.deviceInfo">
      <div
        v-for="(item, index) in infoList"
        :key="item.userId"
        :class="$style.qrCode"
      >
        <qr-code
          :id="`qrcode_${index}`"
          :option="qrCodeOption"
          :width="140"
          :height="140"
          :qrSize="130"
          :qrTextSize="13"
          :qrContent="
            `${item.type === 'BASE' ? '基础版' : '升级版'},${item.deviceId},${
              item.deviceName
            },${item.deviceSecretIntercept}`
          "
          :qrText="item.deviceName"
        />
      </div>
    </div>
    <a-button type="primary" @click="handleDownload">下载二维码</a-button>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import JsZip from 'jszip';
import { saveAs } from 'file-saver';
import { delay } from '@triascloud/utils';
import QrCode from './qrcode.vue';

@Component({ components: { QrCode } })
export default class DownloadQrCode extends Vue {
  @Prop({ type: Object }) info;
  @Prop({ type: Array, default: () => [] }) infoList;

  qrCodeOption = {
    color: {
      background: '#00000000',
      foreground: '#000000FF',
    },
    margin: 3, // 生成的二维码的外边距
  };
  getCanvasImg(dom) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 500;
    canvas.height = 500;
    ctx.drawImage(dom, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL('image/png');
  }
  loading = false;
  async handleDownload() {
    this.loading = true;
    const urls = this.infoList.map(item => ({
      id: item.userId,
      name: item.deviceName,
    }));
    const zip = new JsZip();
    urls.forEach(async (item, index) => {
      const dom = document.getElementById(`qrcode_${index}`);
      const url = this.getCanvasImg(dom);
      zip.file(`${item.name}.png`, url.split(',')[1], {
        base64: true,
      });
    });
    await delay(0);
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, '设备码批量下载');
    });
    this.loading = false;
  }
}
</script>

<style lang="less" module>
.qrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .deviceInfo {
    height: 300px;
    width: 100%;
    margin-bottom: 10px;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px 10px;
    .qrCode {
      text-align: center;
      min-width: 100px;
    }
  }
}
</style>
