<template>
  <canvas :id="id" ref="canvas" :width="width" :height="height"></canvas>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import QRCode from 'qrcode';

@Component()
export default class QrCode extends Vue {
  @Prop({ type: String }) qrContent;
  @Prop({ type: String, default: 'canvas' }) id;
  @Prop({ type: Number, default: 100 }) width;
  @Prop({ type: Number, default: 100 }) height;
  // 二维码尺寸（正方形 长宽相同）
  @Prop({ type: Number, default: 100 }) qrSize;
  // 二维码底部文字
  @Prop({ type: String }) qrText;
  //底部说明文字字号
  @Prop({ type: Number, default: 14 }) qrTextSize;
  /**
   * @description 8位的十六进制的颜色代码说明：最后两位为透明度
   * https://mubu.com/colla/5tG0azdrMIs
   * @name qrcode的option
   * {
   *  color: {
   *    background: '#00000000', // 生成二维码的backgroundColor（背景色）
   *    foreground: '#010599FF', // 生成二维码的foregroundColor（前景色）
   *  },
   *  margin: 0, // 生成的二维码的外边距
   * }
   */
  @Prop({ type: Object, default: () => {} }) option;

  mounted() {
    this.initQrcode();
  }
  /**
   * @argument qrContent    二维码内容
   * @argument qrSize       二维码大小
   * @argument qrText       二维码下方显示的文字
   * @argument qrTextSize   二维码中间显示文字大小(默认14px)
   */
  async initQrcode() {
    let canvas = document.getElementById(this.id);
    const ctx = canvas.getContext('2d');
    const imgDom = await this.getQrImgDom();
    ctx.drawImage(
      imgDom,
      (this.width - this.qrSize) / 2,
      0,
      this.qrSize,
      this.qrSize,
    );
    if (this.qrText) {
      //设置字体
      ctx.font = this.qrTextSize + 'px sans-serif';
      let tw = ctx.measureText(this.qrText).width; // 文字真实宽度
      let ftop = this.qrSize - this.qrTextSize + 6; // 根据字体大小计算文字top
      let fleft = (this.width - tw) / 2; // 根据字体大小计算文字left
      ctx.fillStyle = '#fff';
      ctx.textBaseline = 'top'; //设置绘制文本时的文本基线。
      ctx.fillStyle = '#333';
      ctx.fillText(this.qrText, fleft, ftop);
    }
  }
  async getQrImgDom() {
    const url = await QRCode.toDataURL(this.qrContent, {
      margin: 4,
      ...this.option,
      color: {
        light: this.option?.color ? this.option.color.background : '#ffffffFF',
        dark: this.option?.color ? this.option.color.foreground : '#000000FF',
      },
    });
    const image = new Image();
    image.src = url;
    return new Promise(resolve => {
      image.onload = () => {
        resolve(image);
      };
    });
  }
}
</script>

<style lang="less" module>
.qrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .deviceInfo {
    height: 300px;
    width: 100%;
    margin-bottom: 10px;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px 10px;
    .qrCode {
      text-align: center;
      min-width: 100px;
      .code {
        width: 100px;
        height: 100px;
      }
      .qrTitle {
        font-size: 12px;
        color: var(--font);
      }
    }
  }
}
</style>
