import { globalVueI18n } from '@triascloud/i18n';

/**
 * 设备状态
 * @readonly
 * @enum
 */
export const statusType = {
  UNACTIVE: globalVueI18n.translate('hat.managementModal.personInfo.unactived'),
  ONLINE: globalVueI18n.translate('hat.managementModal.personInfo.online'),
  OFFLINE: globalVueI18n.translate('hat.managementModal.personInfo.offline'),
  STANDBY: globalVueI18n.translate('hat.managementModal.personInfo.standby'),
  DISABLE: globalVueI18n.translate('hat.managementModal.personInfo.disable'),
  EXPIRED: globalVueI18n.translate('hat.managementModal.personInfo.expired'),
};

/**
 * @name 设备事件类型
 * @readonly
 * @enum
 */
export const messageType = {
  FALL: '跌倒报警',
  SOS: 'SOS报警',
  ELECTRIC_SHOCK: '触电报警',
  GAS: '有害气体报警',
  FREE_FALL: '坠落',
  REST: '静止',
  MOTION: '运动',
  CAP: '戴帽',
  UN_CAP: '脱帽提醒',
  OUT_OF_BOUNDS: '越界提醒',
  OFF_DUTY: '离岗提醒',
  UNUSUALLY_STILL: '异常静止',
  LOW_BATTERY: '低电量通知',
  LOCAL_STORAGE: '存储不足通知',
  HEART_RATE: '心率异常通知',
  BLOOD_OXY: '血氧异常通知',
  BLOOD_PRESSURE: '血压异常通知',
  BE_LATE: '迟到',
  ABSENCE_FROM_WORK: '旷工',
  LEAVE_EARLY: '早退',
  CHECK_LOCATION: '定位核验异常',
  BOOT_UP: '开机',
  SHUTDOWN: '关机',
};

/**
 * 设备警报通知类型
 * @readonly
 * @enum
 */
export const alarmType = {
  NORMAL: '正常',
  ALARM: '警报',
  REMIND: '提醒',
  NOTICE: '通知',
};
