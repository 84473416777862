<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item
      :label="$t('hat.deviceManagement.form.deviceCode')"
      prop="deviceName"
    >
      <a-row :gutter="6" :class="$style.row">
        <a-col :span="23">
          <a-input
            placeholder="请联系供应商获取，或用三叠云APP扫描帽壳内的二维码添加"
            v-model="form.deviceName"
            :disabled="!canEdit"
            :maxLength="32"
          />
        </a-col>
        <a-col :span="1">
          <a-popover placement="bottom">
            <template slot="content">
              <div :class="$style.qrcode">
                <qr-code
                  :option="qrCodeOption"
                  :width="100"
                  :height="100"
                  :qrSize="100"
                  qrContent="https://www.sandieyun.com/download"
                />
                <a href="https://www.sandieyun.com/download" target="_blank"
                  >三叠云智联APP<br />扫码录入更便捷</a
                >
              </div>
            </template>
            <a-icon type="qrcode" style="font-size: 16px;" />
          </a-popover>
        </a-col>
      </a-row>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('hat.deviceManagement.form.verificationCode')"
      prop="deviceSecret"
    >
      <a-input
        placeholder="请联系供应商获取，或用三叠云APP扫描帽壳内的二维码添加"
        v-model="form.deviceSecret"
        :disabled="!canEdit"
        :maxLength="128"
      />
    </a-form-model-item>
    <a-form-model-item prop="user">
      <span slot="label">
        {{ $t('hat.deviceManagement.form.user') }}&nbsp;
        <a-tooltip :title="$t('hat.deviceManagement.wearersTips')">
          <a-icon type="info-circle" />
        </a-tooltip>
      </span>
      <x-member-select
        v-model="form.user"
        :view="view"
        :value="range"
        @input="updateRangeValue"
        :type="['org-user', 'role-user', 'user', 'external']"
        :limit="2"
        :multiple="true"
      />
    </a-form-model-item>
    <a-form-model-item prop="groupId">
      <div slot="label" :class="$style.group">
        加入编组&nbsp;
        <a-tooltip title="每设备仅允许加入1个编组，若设备已有分组则加入无效">
          <a-icon type="info-circle" />
        </a-tooltip>
        <div :class="$style.groupOperation">
          <a @click="getGroupList">刷新</a>
          <a @click="addGroup">新建</a>
        </div>
      </div>
      <a-select
        show-search
        allowClear
        v-model="form.groupId"
        :placeholder="$t('hat.deviceManagement.form.select')"
        option-filter-prop="children"
        :filter-option="true"
      >
        <a-select-option
          :value="item.id"
          v-for="item in groupList"
          :key="item.id"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('hat.deviceManagement.form.remark')"
      prop="mark"
    >
      <a-input
        :placeholder="$t('hat.deviceManagement.form.inputRemark')"
        v-model="form.mark"
        :maxLength="128"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { addDevice, putDevice } from '@/services/smart-hat/device-management';
import { getGroupList } from '@/services/smart-hat/device-management';
import { createFormModal } from '@triascloud/x-components';
import Tip from '@/components/tip';
import GroupingForm from './grouping-form.vue';
import QrCode from '@/views/hat/device/components/qrcode.vue';

@Component({ components: { QrCode } })
export default class DeviceForm extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) operationType;

  async mounted() {
    await this.getGroupList();
    await this.getEditData();
  }
  qrCodeOption = {
    color: {
      background: '#00000000',
      foreground: '#000000FF',
    },
    margin: 0, // 生成的二维码的外边距
  };
  form = {
    deviceName: '',
    deviceSecret: '',
    bindType: 'MEMBER',
    groupId: undefined,
    mark: '',
    user: [],
  };
  rules = {
    deviceName: [
      {
        required: true,
        message: this.$t('hat.deviceManagement.form.requiredDeviecCode'),
      },
    ],
    deviceSecret: [
      {
        required: true,
        message: this.$t('hat.deviceManagement.form.requiredVerificationCode'),
      },
    ],
    user: [
      {
        required: false,
        message: this.$t('hat.deviceManagement.form.requiredUser'),
      },
    ],
  };
  canEdit = true;
  async getEditData() {
    if (this.operationType === 'edit') {
      const data = this.editData;
      this.canEdit = false;
      this.initFormData(data);
    }
  }
  initFormData(data) {
    this.form.deviceName = data.deviceName;
    this.form.deviceSecret = data.deviceSecretIntercept;
    this.form.mark = data.mark;
    this.form.bindType = data.bindType;
    this.form.groupId = data.groupId;
    this.form.user = data.users.map(item => ({
      text: item.userName,
      value: item.userId,
      avatar: item.userAvatar,
      memberStatus: item.memberStatus,
      type: 'user',
    }));
  }
  // 选择成员
  range = [];
  view = false;
  updateRangeValue(data) {
    this.range = data[0];
  }
  groupList = [];
  async getGroupList() {
    try {
      const data = {
        current: 1,
        size: 200,
      };
      this.groupList = (await getGroupList(data)).records.map(item => ({
        id: item.pkId,
        name: item.deviceGroupName,
      }));
    } catch {
      return false;
    }
  }
  async addGroup() {
    try {
      const result = await createFormModal(
        () => (
          <GroupingForm ref="group" data={{}} type={'add'} selectData={[]} />
        ),
        {
          width: '450px',
          maskClosable: false,
          title: this.$t('hat.deviceManagement.newGrouping'),
        },
      );
      if (result) {
        await this.getGroupList();
      }
    } catch {
      return false;
    }
  }
  async handleEdit(data) {
    let text = '';
    // 判断目前佩戴人员是否已经被删除
    const isDelete =
      this.form.user.length &&
      this.form.user.every(item => item.text !== this.editData.userName);
    if (this.form.bindType === 'MEMBER' && isDelete && this.editData.userName) {
      text = this.$t('hat.deviceManagement.editModal.messageOne', {
        userName: this.editData.userName,
        user: this.form.user[0].text,
      });
      // text = `本次编辑将移除当前佩戴成员为"${this.editData.userName}"，继续操作将会切换为默认成员"${this.form.user[0].text}"，是否确定操作？`;
    }
    const tips = this.$t('hat.deviceManagement.editModal.');
    if (text) {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt" style="color: #fe5959; font-size: 13px">
              {tips}
            </span>
          </Tip>
        ),
        {
          width: '480px',
          title: this.$t('hat.deviceManagement.operateConfirm'),
          onOk: async () => {
            await putDevice(data);
          },
        },
      );
    } else {
      await putDevice(data);
    }
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (this.form.user.length > 100) {
          this.$message.error(
            this.$t('hat.deviceManagement.editModal.validate.messageThree'),
          );
          valid = false;
        }
        if (valid) {
          const type = this.operationType === 'add';
          try {
            const {
              deviceName,
              deviceSecret,
              bindType,
              mark,
              user,
              groupId,
            } = this.form;
            const data = {
              deviceName,
              deviceSecret,
              bindType,
              mark,
              groupId,
              userIds: user.map(item => item.value),
            };
            if (this.operationType === 'edit') {
              data.deviceId = this.editData.deviceId;
            }
            const str = type
              ? this.$t('hat.deviceManagement.editModal.addSuccess')
              : this.$t('hat.deviceManagement.editModal.modified');
            this.operationType === 'add'
              ? await addDevice(data)
              : await this.handleEdit(data);
            this.$message.success(str);
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module>
.tipText {
  color: #999999;
}
.qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    margin-top: 5px;
  }
}
.group {
  display: flex;
  align-items: center;
  .groupOperation {
    margin-left: auto;
    a {
      margin-left: 10px;
    }
  }
}
</style>
